
























import { VNode } from "vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import { FormField } from "@/components/form-components/types";

@Component({
  components: {},
})
export default class VForm extends Vue implements FormField {
  @Prop({}) public submitHandler!: (event: Event) => Promise<any>;
  @Prop({}) public cta!: string;
  @Prop({}) public messages!: { [key: string]: string };
  @Prop({}) public cancelable!: boolean;
  @Prop({}) public formLoading!: boolean;
  @Prop({ default: false }) public disabled!: boolean;
  @Prop({ default: true }) public showCtas!: boolean;

  public failedSubmissionOnce = false;
  public responseStatus = "";
  public responseMessage = "";

  get ctaText() {
    return this.formLoading ? "Bezig..." : this.cta;
  }

  public validate() {
    if (this.$slots.default) {
      let allValid = true;

      function doValidate(vNode: VNode) {
        if (
          vNode.componentInstance &&
          typeof (vNode.componentInstance as any).validate === "function"
        ) {
          if ((vNode.componentInstance as any).validate() === false) {
            allValid = false;
          }
        } else if (vNode?.children?.length) {
          vNode.children.forEach((childNode) => doValidate(childNode));
        }
      }

      this.$slots.default.forEach((vNode) => {
        doValidate(vNode);
      });
      return allValid;
    } else {
      return true;
    }
  }

  public submit(event: Event) {
    if (this.validate() && this.isValid()) {
      if (this.submitHandler) {
        this.submitHandler(event).catch((reason) => {
          this.failedSubmissionOnce = true;
        });
      }
    } else {
      this.$snotify.error("Niet alle velden zijn correct ingevuld.");
      this.failedSubmissionOnce = true;
    }
  }

  protected onInput() {
    this.failedSubmissionOnce = false;
  }

  private isValid() {
    return this.$el && (this.$el as HTMLFormElement).checkValidity();
  }
}
