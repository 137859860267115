


















































































































import Page from "@/components/layouts/Page.vue";
import TheHeader from "@/components/TheHeader.vue";
import FormInputField from "@/components/form-components/FormInputField.vue";
import VForm from "@/components/VForm.vue";
import { Component, Prop, Ref, Watch } from "vue-property-decorator";
import { Customer, Division, divisions } from "@/models/user";
import { Account } from "@/interfaces/Account";
import ExistingUserSearch from "@/components/usermanagement/ExistingUserSearch.vue";
import accessControl from "@/api/access_control";
import djlMultiselect from "@/components/form-components/djlMultiselect.vue";
import { TranslateResult } from "vue-i18n";
import { mixins } from "vue-class-component";
import TranslatableComponent, {
  Translatable,
} from "@/lib/mixins/translateable";

export interface SelectedDivision {
  key: Division;
  value: TranslateResult;
}

@Component({
  components: {
    ExistingUserSearch,
    VForm,
    FormInputField,
    djlMultiselect,
    Page,
    TheHeader,
  },
})
@Translatable("usermanagement")
export default class UserManagementLinkUser extends mixins(
  TranslatableComponent
) {
  private messages = {};
  private user: Customer | null = null;
  private account: Account | null = null;
  private department: SelectedDivision | null = null;
  private jobtitle: string | null = null;
  private users: Customer[] = [];
  private accounts: Account[] = [];
  private isSubmitting: boolean = false;

  @Prop() readonly userId!: string;
  @Ref() readonly userSelect!: djlMultiselect;
  @Ref() readonly accountSelect!: djlMultiselect;
  @Ref() readonly departmentSelect!: djlMultiselect;

  private nameWithLang(user: Customer) {
    return `${user.firstName} ${user.lastName}`;
  }

  @Watch("user")
  private userSelected(user: Customer | null) {
    if (user === null) {
      this.account = null;
    }
  }

  private get formFilled(): boolean {
    return !this.user || !this.account || !this.department || !this.jobtitle;
  }

  private get departments(): SelectedDivision[] {
    return divisions.map((dep) => {
      return { key: dep, value: this.$t(`models.division.${dep}`) };
    });
  }

  createContactPerson(event: Event): Promise<any> {
    this.isSubmitting = true;
    return new Promise((resolve, reject) => {
      accessControl.usermanagement.contacts
        .create({
          accountId: this.account?.id || "",
          naturalPersonId: this.user?.id || "",
          department: this.department?.key || null,
          jobtitle: this.jobtitle || "",
        })
        .then((e) => {
          this.$snotify.success(
            this.translated_view_value("user_connected_succesfully")
          );
          this.user = null;
          this.account = null;

          this.$router.push({
            name: "usermanagement-detail-new",
            query: { contactId: e.data.id },
          });

          resolve(e);
        })
        .catch((e) => {
          reject(e);
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    });
  }

  private cancelClicked(): void {
    this.account = null;
    this.user = null;
    this.department = null;
    this.jobtitle = "";
    this.userSelect?.resetValue();
    this.accountSelect?.resetValue();
    this.departmentSelect?.resetValue();
  }

  mounted() {
    accessControl.usermanagement.users().then((value) => {
      this.users = value.data;
      if (this.userId) {
        this.user = this.users.find(({ id }) => id === this.userId) || null;
      }
    });
    accessControl.accounts.accountsWithRole("user_management").then((value) => {
      this.accounts = value.data;
    });
  }
}
