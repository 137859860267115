














import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";
import Multiselect from "vue-multiselect";

@Component({ components: { Multiselect } })
export default class ExistingUserSearch<T> extends Vue {
  @Prop()
  private items!: T[];

  private selected: T | null = null;

  @Watch("selected")
  @Emit("input")
  private selectedChanged(val: T, oldVal: T) {
    return val;
  }

  @Prop()
  private label!: (item: T) => {};
}
